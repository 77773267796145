import {Report, PropertyValue} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ARRAY22, getShiftName, getTypeName} from "../../common";

export interface ViewUnloadingReportProperties {
    report: Report,
    shifts: PropertyValue[],
    bricks_types: PropertyValue[],
}

interface UnloadingReportState {
}

export class ViewUnloadingReportComponent extends React.Component<ViewUnloadingReportProperties, UnloadingReportState> {

    constructor(props: ViewUnloadingReportProperties) {
        super(props);
        this.state = {}
    }

    render() {
        const rd = JSON.parse(this.props.report.request_data);

        let totalWasted = 0;
        let totalBricks = 0;
        let totalSecondQuality = 0;

        const tableBody = [];
        ARRAY22.forEach(
            (i: number, idx: number) => {
                if(rd['brickType1_' + idx] && rd['brickType1_' + idx] > -1) {
                    totalWasted += parseInt(rd['waste_' + idx] || "0");
                    totalSecondQuality += parseInt(rd['not_good_' + idx] || "0");
                    totalBricks += (rd['palet_180_' + idx] === false) ? 150 : 180;
                }
                tableBody.push(rd['brickType1_' + idx] && rd['brickType1_' + idx] > -1 ?
                    <tr key={idx}>
                        <td style={{width: "100px"}}>{rd['wagonNumber_' + idx]}</td>
                        <td>{getShiftName(this.props.shifts, rd['shift_' + idx])}</td>
                        <td>{getTypeName(this.props.bricks_types, rd['brickType1_' + idx])}</td>
                        {/*<td>{getTypeName(this.props.bricks_types, rd['brickType2_' + idx])}</td>*/}
                        <td className={"text-end"}>
                            {
                                (rd['brickType1_' + idx] == 0 || rd['brickType1_' + idx] == 2) &&
                                <>{(rd['palet_180_' + idx] === false) ? '150' : '180'}</>
                            }
                        </td>
                        <td className={"w-120px text-end"}>{rd['waste_' + idx] > 0 ? rd['waste_' + idx] : ''}</td>
                        <td>
                            {
                                (rd['waste_reason_pechene_' + idx] ? 'при печене; ' : '') +
                                (rd['waste_reason_robot_' + idx] ? 'от робота; ' : '') +
                                (rd['extr_reason_linia_' + idx] ? 'по линията; ' : '')
                            }
                        </td>
                        <td className={"w-120px text-end"}>{rd['not_good_' + idx] > 0 ? rd['not_good_' + idx] : ''}</td>
                    </tr>
                    : '');
            }
        );
        tableBody.push(<tr>
            <td colSpan={3} className={"text-end"}>Общо: </td>
            <td className={"text-end"}></td>
            <td className={"text-end"}>{totalWasted}</td>
            <td></td>
            <td className={"text-end"}>{totalSecondQuality}</td>
        </tr>);

        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            {
                                this.props.report.base_info.shift_id === 99 &&
                                <>
                                    <div className="col">
                                        Начало:<br/><strong>{this.props.report.base_info.from_time}</strong>
                                    </div>
                                    <div className="col">
                                        Край:<br/><strong>{this.props.report.base_info.to_time}</strong>
                                    </div>
                                </>
                            }
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Производство"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        <th className={"w-100px"}>№ вагон</th>
                                        <th>Смяна</th>
                                        <th>Вид тухла</th>
                                        {/*<th>Вид тухла 2</th>*/}
                                        <th>Брой</th>
                                        <th className={"w-120px"}>Брак</th>
                                        <th>Причина</th>
                                        <th className={"w-120px"}>II кач.</th>
                                        </thead>
                                        <tbody>
                                        {
                                            tableBody
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    {rd.comments || "Няма бележки към отчета"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
