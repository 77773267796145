import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import {API} from "../../api";
import {Account, API_CALL} from "../../types";
import {getDaysInMonth, isSuccess, numberToCurrencyString} from "../../common";
import {CurrencyInput} from "../../common/CurrencyInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {BonusUser} from "./BonusPlusControlModal";
import BonusPlusConfigParameterTable from "./BonusPlusConfigParameterTable";

export type BonusPlusConfigTypeType =
    "UNLOAD_PLATNA"
    | "UNLOAD_THERMO"
    | "UNLOAD_ISOBRIX1"
    | "UNLOAD_ISOBRIX2"
    | "UNLOAD_WASTE_PLATNA"
    | "UNLOAD_WASTE_THERMO"
    | "UNLOAD_WASTE_ISOBRIX1"
    | "UNLOAD_WASTE_ISOBRIX2"
    | "ORDERING_WASTE_PLATNA"
    | "ORDERING_WASTE_THERMO"
    | "ORDERING_WASTE_ISOBRIX1"
    | "ORDERING_WASTE_ISOBRIX2"
    | "PALETS_PLATNA"
    | "PALETS_THERMO"
    | "PALETS_ISOBRIX1"
    | "PALETS_ISOBRIX2";

export const BonusPlusConfigTypeTypeNames = [
    "UNLOAD_PLATNA", "UNLOAD_THERMO", "UNLOAD_ISOBRIX1", "UNLOAD_ISOBRIX2", "UNLOAD_WASTE_PLATNA", "UNLOAD_WASTE_THERMO",
    "UNLOAD_WASTE_ISOBRIX1", "UNLOAD_WASTE_ISOBRIX2", "ORDERING_WASTE_PLATNA", "ORDERING_WASTE_THERMO",
    "ORDERING_WASTE_ISOBRIX1", "ORDERING_WASTE_ISOBRIX2", "PALETS_PLATNA", "PALETS_THERMO", "PALETS_ISOBRIX1", "PALETS_ISOBRIX2"];

export type BonusPlusConfigType = {
    configType: BonusPlusConfigTypeType;
    standart: number;
    standartWeight: number;
    standartBonus: number;
    upStep: number;
    upStepBonus: number;
    downStep: number;
    downStepBonus: number;
}

export const EMPTY_BONUS_PLUS_CONFIG = (configType: BonusPlusConfigTypeType): BonusPlusConfigType => {
    return {
        configType: configType,
        standart: 0,
        standartWeight: 0,
        standartBonus: 0,
        upStep: 0,
        upStepBonus: 0,
        downStep: 0,
        downStepBonus: 0,
    }
};

export function BonusPlusConfigComponent({users, onChangeData, month, year}: {
    users: Array<BonusUser>,
    onChangeData: (newData: Array<BonusUser>) => void,
    month: number,
    year: number
}) {
    const [localUsers, setLocalUsers] = useState<Array<BonusUser>>([]);
    const [selected, setSelected] = useState<BonusUser | null>(null);
    const [config, setConfig] = useState<Map<BonusPlusConfigTypeType, BonusPlusConfigType>>(new Map());
    const [allUsers, setAllUsers] = useState<any>([]);
    const [newUser, setNewUser] = useState<string | undefined>(undefined);

    const loadData = () => {
        const m = new Map<BonusPlusConfigTypeType, BonusPlusConfigType>();
        if (selected && localUsers.length > 0) {
            selected?.config.forEach((v, k) => m.set(k, v));
            BonusPlusConfigTypeTypeNames.forEach((k: string) => {
                if (!m.has(k as BonusPlusConfigTypeType)) {
                    m.set(k as BonusPlusConfigTypeType, EMPTY_BONUS_PLUS_CONFIG(k as BonusPlusConfigTypeType));
                }
            });
        }
        setConfig(m);
    }

    const updateStandartBonus = (newData: Array<BonusUser>) => {
        for (const u of newData) {
            const config = new Map<BonusPlusConfigTypeType, BonusPlusConfigType>(u.config);

            config.forEach((v, k) => {
                v.standartBonus = u.maxBonus * v.standartWeight / 100;
            });

            u.config = config;
        }
        onChangeData([...newData]);
    }

    useEffect(() => {
        API.getAllAccounts(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setAllUsers(
                        apiCall.data.data.users.map((u: Account) => {
                            return {'id': u.id, 'name': u.name, 'department': u.department}
                        })
                            .sort((i1: Account, i2: Account) => i1.name > i2.name ? 1 : (i1.name < i2.name ? -1 : 0))
                    )
                }
            }
        );
        setConfig(new Map());
    }, []);

    useEffect(
        () => {
            const _users = [...users];
            setLocalUsers(_users);
            if (_users.length > 0) {
                setSelected(_users[0]);
            } else setSelected(null);
        }, [users]
    );
    useEffect(() => {
        loadData()
    }, [selected]);

    function selectUser(u: any) {
        setSelected(u);
    }

    function updateData() {
        updateStandartBonus(localUsers);
    }

    useEffect(() => {
        if (selected) {
            selected.config = config;
            updateData();
        }
    }, [config]);

    function removeUser(id: number) {
        const newList = localUsers.filter(i => i.id != id);
        setLocalUsers(newList);
        onChangeData(newList);
    }

    function addUser() {
        if (!newUser) return;

        const _newUser = allUsers.find((u: any) => u.id == (newUser || -1));
        if (!_newUser) return;

        const m = new Map<BonusPlusConfigTypeType, BonusPlusConfigType>();
        if (selected && localUsers.length > 0) {
            selected?.config.forEach((v, k) => m.set(k, v));
        }
        const newList = [...localUsers,
            {
                id: _newUser.id,
                name: _newUser.name,
                position: _newUser.department,
                minBonus: 0,
                maxBonus: 0,
                config: m
            } as BonusUser
        ];

        setLocalUsers(newList);
        onChangeData(newList);
        selectUser(newList[newList.length - 1]);
    }

    const updateStandartWeight = (prefix: string, value: number) => {
        const newConfig = new Map(config);
        const keys: Array<BonusPlusConfigTypeType> = [
            `${prefix}_PLATNA` as BonusPlusConfigTypeType,
            `${prefix}_THERMO` as BonusPlusConfigTypeType,
            `${prefix}_ISOBRIX1` as BonusPlusConfigTypeType,
            `${prefix}_ISOBRIX2` as BonusPlusConfigTypeType
        ];

        keys.forEach((k: BonusPlusConfigTypeType) => {
            const newConfigType = newConfig.get(k);
            if (newConfigType) {
                newConfigType.standartWeight = value;
                newConfig.set(k, newConfigType);
            }
        });

        let totalWeight = 0;
        const unloadConfig = newConfig.get("UNLOAD_PLATNA");
        if (unloadConfig) totalWeight += unloadConfig.standartWeight;
        const unloadWasteConfig = newConfig.get("UNLOAD_WASTE_PLATNA");
        if (unloadWasteConfig) totalWeight += unloadWasteConfig.standartWeight;
        const orderingWasteConfig = newConfig.get("ORDERING_WASTE_PLATNA");
        if (orderingWasteConfig) totalWeight += orderingWasteConfig.standartWeight;
        let paletsWeight = 100 - totalWeight;
        if (paletsWeight < 0) paletsWeight = 0;
        const paletsConfigPlatna = newConfig.get("PALETS_PLATNA");
        if (paletsConfigPlatna) {
            paletsConfigPlatna.standartWeight = paletsWeight;
            newConfig.set("PALETS_PLATNA", paletsConfigPlatna);
        }
        const paletsConfigThermo = newConfig.get("PALETS_THERMO");
        if (paletsConfigThermo) {
            paletsConfigThermo.standartWeight = paletsWeight;
            newConfig.set("PALETS_THERMO", paletsConfigThermo);
        }
        const paletsConfigIsobrix1 = newConfig.get("PALETS_ISOBRIX1");
        if (paletsConfigIsobrix1) {
            paletsConfigIsobrix1.standartWeight = paletsWeight;
            newConfig.set("PALETS_ISOBRIX1", paletsConfigIsobrix1);
        }
        const paletsConfigIsobrix2 = newConfig.get("PALETS_ISOBRIX2");
        if (paletsConfigIsobrix2) {
            paletsConfigIsobrix2.standartWeight = paletsWeight;
            newConfig.set("PALETS_ISOBRIX2", paletsConfigIsobrix2);
        }

        setConfig(newConfig);
    }

    const daysInMonth = useMemo(() => getDaysInMonth(month, year), [year, month]);

    return (
        <div className={"overflow-auto"}>
            <BlockTitleComponent title={"Включени в бонусната система служители"} isBlack={true}/>
            <Table size={"sm"}>
                <thead>
                <tr>
                    <th rowSpan={2}>Имe на служителя</th>
                    <th rowSpan={2}>Длъжност</th>
                    <th colSpan={2} className="text-center text-nowrap w-160px">Макс.бонус по стандарт</th>
                    <th rowSpan={2}/>
                </tr>
                <tr>
                    <th className={"text-end w-100px"}>Месечно</th>
                    <th className={"text-end w-100px"}>Дневно</th>
                </tr>
                </thead>
                <tbody>
                {
                    localUsers.length === 0 &&
                    <tr>
                        <td colSpan={4}>Няма регистрирани служители в бонусната система.</td>
                    </tr>
                }
                {
                    localUsers.map(
                        (u: any, i: number) => <tr key={u.id}
                                                   className={u.id === selected?.id ? " taifunTheme fw-bold" : ""}>
                            <td onClick={() => selectUser(u)}>{u.name}</td>
                            <td onClick={() => selectUser(u)}>{u.position}</td>
                            {u.id === selected?.id ?
                                <>
                                    <td className={"text-end justify-content-end justify-content-end"}>
                                        <CurrencyInput className="form-control small text-end p-1 m-0 w-100px"
                                                       onChange={(v: number) => {
                                                           u.maxBonus = v ;
                                                           updateData()
                                                       }}
                                                       value={u.maxBonus}/>
                                    </td>
                                    <td className={"text-end justify-content-end justify-content-end"}>
                                        <CurrencyInput className="form-control small text-end p-1 m-0 w-100px"
                                                       readOnly={true} disabled={true}
                                                       value={Math.round(u.maxBonus / daysInMonth)}/>
                                    </td>
                                </>
                                :
                                <>
                                    <td className="text-end w-100px"
                                        onClick={() => selectUser(u)}>{numberToCurrencyString(u.maxBonus * daysInMonth)}</td>
                                    <td className="text-end w-100px"
                                        onClick={() => selectUser(u)}>{numberToCurrencyString(u.maxBonus)}</td>
                                </>
                            }
                            <td className={"text-center w-50px"}>
                                <Button size={"sm"} onClick={() => removeUser(u.id)}><FontAwesomeIcon
                                    icon={faTrash}/></Button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

            {
                localUsers.length > 0 &&
                <>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <BlockTitleComponent title={"Производителност, брой тухли"} isBlack={true}/>
                                        </Col>
                                        <Col xs={3}>
                                            <Row className={"bg-dark text-light p-1 m-0"}>
                                                <Col>
                                                    Тежест (%):
                                                </Col>
                                                <Col className={"pt-0 m-0"}>
                                                    <Form.Control
                                                        value={config.get("UNLOAD_PLATNA")?.standartWeight ?? "0"}
                                                        type={"number"} size={"sm"} className={"text-end"}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            if (isNaN(value)) return;
                                                            updateStandartWeight("UNLOAD", value);
                                                        }}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Термо"} isRed={true} />
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={false}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_THERMO") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_THERMO")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_THERMO", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Плътна"} isRed={true} />
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={false}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_PLATNA") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_PLATNA")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_PLATNA", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 12"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={false}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_ISOBRIX1") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_ISOBRIX1")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_ISOBRIX1", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 25"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={false}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_ISOBRIX2") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_ISOBRIX2")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_ISOBRIX2", c)))}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <BlockTitleComponent title={"Брак от разтоварването, брой тухли"} isBlack={true}/>
                                        </Col>
                                        <Col xs={3}>
                                            <Row className={"bg-dark text-light p-1 m-0"}>
                                                <Col>
                                                    Тежест (%):
                                                </Col>
                                                <Col className={"pt-0 m-0"}>
                                                    <Form.Control
                                                        value={config.get("UNLOAD_WASTE_PLATNA")?.standartWeight ?? "0"}
                                                        type={"number"} size={"sm"} className={"text-end"}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            if (isNaN(value)) return;
                                                            updateStandartWeight("UNLOAD_WASTE", value);
                                                        }}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Термо"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_WASTE_THERMO") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_WASTE_THERMO")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_WASTE_THERMO", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Плътна"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_WASTE_PLATNA") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_WASTE_PLATNA")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_WASTE_PLATNA", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 12"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_WASTE_ISOBRIX1") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_WASTE_ISOBRIX1")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_WASTE_ISOBRIX1", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 25"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("UNLOAD_WASTE_ISOBRIX2") || EMPTY_BONUS_PLUS_CONFIG("UNLOAD_WASTE_ISOBRIX2")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("UNLOAD_WASTE_ISOBRIX2", c)))}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <BlockTitleComponent title={"Брак от реденето, брой тухли"} isBlack={true}/>
                                        </Col>
                                        <Col xs={3}>
                                            <Row className={"bg-dark text-light p-1 m-0"}>
                                                <Col>
                                                    Тежест (%):
                                                </Col>
                                                <Col className={"pt-0 m-0"}>
                                                    <Form.Control
                                                        value={config.get("ORDERING_WASTE_PLATNA")?.standartWeight ?? "0"}
                                                        type={"number"} size={"sm"} className={"text-end"}
                                                        onChange={(e) => {
                                                            const value = parseInt(e.target.value);
                                                            if (isNaN(value)) return;
                                                            updateStandartWeight("ORDERING_WASTE", value);
                                                        }}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Термо"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("ORDERING_WASTE_THERMO") || EMPTY_BONUS_PLUS_CONFIG("ORDERING_WASTE_THERMO")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("ORDERING_WASTE_THERMO", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Плътна"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("ORDERING_WASTE_PLATNA") || EMPTY_BONUS_PLUS_CONFIG("ORDERING_WASTE_PLATNA")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("ORDERING_WASTE_PLATNA", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 12"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("ORDERING_WASTE_ISOBRIX1") || EMPTY_BONUS_PLUS_CONFIG("ORDERING_WASTE_ISOBRIX1")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("ORDERING_WASTE_ISOBRIX1", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 25"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("ORDERING_WASTE_ISOBRIX2") || EMPTY_BONUS_PLUS_CONFIG("ORDERING_WASTE_ISOBRIX2")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("ORDERING_WASTE_ISOBRIX2", c)))}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col>
                                            <BlockTitleComponent title={"Пелети в печка и пещ, кг"} isBlack={true}/>
                                        </Col>
                                        <Col xs={3}>
                                            <Row className={"bg-dark text-light p-1 m-0"}>
                                                <Col>
                                                    Тежест (%):
                                                </Col>
                                                <Col className={"pt-0 m-0"}>
                                                    <Form.Control
                                                        value={config.get("PALETS_PLATNA")?.standartWeight ?? "0"}
                                                        type={"number"} size={"sm"} className={"text-end"}
                                                        readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Термо"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("PALETS_THERMO") || EMPTY_BONUS_PLUS_CONFIG("PALETS_THERMO")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("PALETS_THERMO", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"Плътна"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("PALETS_PLATNA") || EMPTY_BONUS_PLUS_CONFIG("PALETS_PLATNA")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("PALETS_PLATNA", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 12"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("PALETS_ISOBRIX1") || EMPTY_BONUS_PLUS_CONFIG("PALETS_ISOBRIX1")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("PALETS_ISOBRIX1", c)))}/>
                                </Col>
                                <Col>
                                    <BlockTitleComponent title={"ISOBRIX 25"} isRed={true}/>
                                    <BonusPlusConfigParameterTable
                                        isLessIsMore={true}
                                        daysCnt={daysInMonth}
                                        config={config.get("PALETS_ISOBRIX2") || EMPTY_BONUS_PLUS_CONFIG("PALETS_ISOBRIX2")}
                                        userMaxBonus={selected?.maxBonus || 0}
                                        onChange={(c) => setConfig(new Map(config.set("PALETS_ISOBRIX2", c)))}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            }

            <BlockTitleComponent title={"Добави нов служител"} isBlack={true}/>
            <Row className={"mw-100"}>
                <Col>
                    <Form.Control as="select" onChange={u => setNewUser(u.target.value)} value={newUser}>
                        <option></option>
                        {
                            allUsers.map(
                                (u: any) => <option value={u.id}>{u.name}, {u.department}</option>
                            )
                        }
                    </Form.Control>
                </Col>
                <Col xs={"auto"}>
                    <Button size={"sm"} onClick={() => addUser()} disabled={!newUser} variant={"danger"}><FontAwesomeIcon
                        icon={faPlus}/></Button>
                </Col>
            </Row>
        </div>
    )
}