import React from "react";
import {API_CALL, City, Client, HeaterReport} from "../types";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {API} from "../api";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast} from "../common";
import {Pager} from "../components/common/Pager";
import {ClientsList} from "../components/clients/ClientsList";
import {EditClientModal} from "../components/clients/EditClientModal";
import {ConfirmDeleteModal} from "../common/ConfirmDeleteModal";
import {EditClientAccountModal} from "../components/clients/EditClientAccountModal";
import {ClientsFilter} from "../components/clients/ClientsFilter";


interface ClientsPageProps {
    filter?: any;
    onSelect?: (client: Client, city: City) => any;
    onClose?: () => any;
}

interface ClientsPageState {
    clients: Client[],
    pageCount: number,
    currentPage: number,
    loading: boolean,
    formValues: Map<string, any>;
    filter: Map<string, any>;
    editClient: Client | null;
    editClientAccount: Client | null;
    dialogConfirmDeleteVisible: boolean;
}

export class ClientsPage extends React.Component<ClientsPageProps, ClientsPageState> {
    private selectedClient: Client | null = null;
    private applyFilterTimeout: any = null;

    constructor(props: any) {
        super(props);

        this.state = {
            clients: [],
            pageCount: 0,
            currentPage: 0,
            loading: false,
            formValues: new Map<string, any>(),
            filter: new Map<string, any>(props.filter),
            editClient: null,
            editClientAccount: null,
            dialogConfirmDeleteVisible: false,
        };

        this.state.formValues.set("clients_number", '');

        this.handleFormChange = this.handleFormChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.createNewClient = this.createNewClient.bind(this);
        this.saveClient = this.saveClient.bind(this);
        this.editClient = this.editClient.bind(this);
        this.viewClientAccount = this.viewClientAccount.bind(this);
        this.removeClient = this.removeClient.bind(this);
        this.doRemoveClient = this.doRemoveClient.bind(this);
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }


    componentDidMount() {
        this.loadData();
    }

    loadData(pageIdx: number = 0) {
        API.getClients(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    this.setState({
                        clients: apiCall.data.data.clients.map(
                            (i: any) => {
                                return {...i, cities: JSON.parse(i.cities)} as Client
                            }
                        ),
                        pageCount: apiCall.data.data.pages,
                        currentPage: apiCall.data.data.page,
                    });
                }
            }, mapToObject(this.state.filter), pageIdx
        );
    }

    saveClient(client: Client) {
        this.setState({editClient: null});
        this.loadData(this.state.currentPage);
    }

    createNewClient() {
        this.setState({
            editClient: {
                id: -1, name: '', isForeign: 0, cities: [], balance_avans_bank: 0, balance_avans_cash: 0,
                balance_bank: 0, balance_cash: 0, balance_credit: 0, balance_logistics: 0, balance_palet: 0,
                balance_palet_iso: 0
            }
        });
    }

    editClient(client: Client) {
        this.setState({editClient: client});
    }

    viewClientAccount(client: Client) {
        this.setState({editClientAccount: client});
    }

    applyFilter(filter: Map<string, any>) {
        const me = this;
        this.state.filter.set('name', filter.get('name') || '');
        this.state.filter.set('cities', filter.get('cities') || '');

        if (this.applyFilterTimeout) window.clearTimeout(this.applyFilterTimeout);
        this.applyFilterTimeout = window.setTimeout(() => {
            me.loadData(0);
            me.applyFilterTimeout = null;
        }, 1000);
    }

    removeClient(client: Client) {
        this.selectedClient = client;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doRemoveClient(client: Client | null) {
        if (client)
            API.deleteClientData(
                (apiCall: API_CALL) => {
                    const _isLoading = isLoading(apiCall);
                    this.setState({loading: _isLoading, dialogConfirmDeleteVisible: _isLoading});
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка');
                    }
                    if (isSuccess(apiCall)) this.loadData(this.state.currentPage);
                }, client.id
            );
    }

    onPageChange(newPage: number) {
        this.setState({currentPage: newPage});
        this.loadData(newPage);
    }

    render() {

        return (
            <>
                <div className="container" style={{maxHeight: "95vh", overflow: "auto"}}>
                    <div className="card shadow w-100">
                        <div className="card-header text-center row">
                            <div className="col-2 text-start">
                                {
                                    this.props.onClose ?
                                        <a onClick={this.props.onClose}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon
                                                icon={faArrowLeft}/></span>
                                        </a>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon
                                                icon={faArrowLeft}/></span></Link>
                                }
                            </div>
                            <h2 className="col">Търговски клиенти</h2>
                            <div className="col-auto">
                                <a className={"btn btn-secondary"} onClick={this.createNewClient}><FontAwesomeIcon
                                    icon={"plus"}/> Добави</a>
                                <a className={"btn btn-secondary"} onClick={() => this.loadData(this.state.currentPage)}><FontAwesomeIcon
                                    icon={"sync-alt"}/> Опресни</a>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className={"row"}>
                                <div className={"col"}>


                                    <table className={"table table-sm table-hover table-no-padding table-bordered"}>
                                        <thead>
                                        <tr className={"bg-secondary text-light text-center"}>
                                            <th style={{minWidth: '9em'}} rowSpan={2}>Клиент</th>
                                            <th rowSpan={2}>Град</th>
                                            <th colSpan={8}>Баланси</th>
                                            <th className={"w-160px"} rowSpan={2}></th>
                                        </tr>
                                        <tr className={"bg-secondary text-light text-center"}>
                                            <th className={"w-80px"}>Ав.банка</th>
                                            <th className={"w-80px"}>Ав.кеш</th>
                                            <th className={"w-80px"}>Кредит</th>
                                            <th className={"w-80px"}>Банка</th>
                                            <th className={"w-80px"}>Кеш</th>
                                            <th className={"w-80px"}>Трансп.</th>
                                            <th className={"w-80px"}>Палети</th>
                                            <th className={"w-80px"}>П-ти ISO</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <ClientsFilter
                                            filter={this.state.filter}
                                            loading={this.state.loading}
                                            onApplyFilter={this.applyFilter}/>
                                        {
                                            <ClientsList
                                                loading={this.state.loading}
                                                clients={this.state.clients}
                                                onEditClient={this.editClient}
                                                onRemoveClient={this.removeClient}
                                                onViewClientAccount={this.viewClientAccount}
                                                onSelect={this.props.onSelect}
                                            />
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                this.state.pageCount > 0 &&
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <Pager currentPage={this.state.currentPage}
                                               pageCount={this.state.pageCount}
                                               onChange={this.onPageChange.bind(this)}
                                               disabled={this.state.loading}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.editClientAccount &&
                    <EditClientAccountModal client={this.state.editClientAccount}
                                            onReload={() => this.loadData(this.state.currentPage)}
                                            onClose={() => {
                                                this.setState({editClientAccount: null})
                                            }}
                                            modalStyle={{zIndex: 10020}}
                    />
                }
                {
                    this.state.editClient &&
                    <EditClientModal client={this.state.editClient}
                                     onSave={client => this.saveClient(client)}
                                     onClose={() => {
                                         this.setState({editClient: null})
                                     }}/>
                }

                {
                    this.state.dialogConfirmDeleteVisible &&
                    <ConfirmDeleteModal
                        working={this.state.loading}
                        onConfirm={() => this.doRemoveClient(this.selectedClient)}
                        onReject={() => this.setState({dialogConfirmDeleteVisible: false})}
                    />
                }
            </>
        );
    }

}
