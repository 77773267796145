import {useEffect, useState} from "react";
import { stringToCurrency, stringToCurrencyString } from "../common";

export function CurrencyInput(props: any) {
    const [value, setValue] = useState(props?.value);

    useEffect(() => {
        setValue(props?.value);
    }, [props.value]);

    function updateValue() {
        if(props.onChange) {
            props.onChange(stringToCurrency(value || 0));
        }
    }


    return (
    <input type="text" className={props.className} readOnly={!props.onChange}
    onChange={ el => setValue(el.target.value) }
    onBlur={ () => updateValue() }
    value={value}
    />)
}