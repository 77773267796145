import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {faAngleRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {API_CALL, NET_STATUS} from "../types";
import {FACTORY} from "../factories";
import {API} from "../api";
import { isSuccess } from "../common";
import {useNavigate, useSearchParams} from "react-router-dom";

interface LoginState {
    api: API_CALL;
    users: any;
    filter: string;
    selectedUser: any;
}

export function LoginPage(props: any) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loginState, setLoginState] = useState<LoginState>({users: [], filter: '', api: FACTORY.createApiCall(NET_STATUS.LOADING), selectedUser: null});
    const passwordInput = useRef<HTMLInputElement>(null);
    const history = useNavigate();

    const handleGetUserListEvent = function(apiCall: API_CALL) {
        setLoginState(prevState => {
            return {...prevState, users: apiCall.data?.data?.users || null, api: apiCall}
        });
    }

    const handleLogInEvent = function(apiCall: API_CALL) {
        setLoginState(prevState => {
            return {...prevState, api: apiCall}
        });
        if(isSuccess(apiCall)) {
            window.location.href = "/home" + API.getAPITokenAsQuery();
        }
    }

    useEffect(() => {
        let key = searchParams.get("key");
        if(key) {
            API.postUserLoginByKey(handleLogInEvent, key as string);
        } else {
            API.getAllUsersNotSigned(handleGetUserListEvent)
        }
    }, []);

    const doLogin = () => {
        const password = passwordInput.current?.value || "";
        API.postUserLogin(handleLogInEvent, loginState.selectedUser.user, password.toString());
    }

    const UserItemComponent = function (props: any) {
        return (
            <div className="row align-items-center p-2 pr-0 m-0 mb-2 shadow-sm">
                <div className="col">
                    <h5 className="list-group-item-heading">{props.user.user}</h5>
                    {/*<p className="list-group-item-text font-italic small">*/}
                    {/*    { props.user.modules.join(", ") }*/}
                    {/*</p>*/}
                </div>
                <div className="col-auto text-end">
                    <a href="#" className="btn btn-outline-primary list-group-item" onClick={props.onSelectUser}><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></a>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="h-100 row align-items-center w-100">
                <div className="col p-3 row justify-content-center">

                    <div className="col-lg-5 col-12">
                        <div className="card shadow ">
                            <div className="card-header row m-0">
                                <h4 className="col-auto pl-0">Вход в системата:</h4>
                                <div className="col text-end pt-2">
                                    <FontAwesomeIcon icon="user-lock" size="lg"/>
                                </div>
                            </div>
                            <div className="card-body overflow-auto" style={{maxHeight: "60vh"}}>

                                {
                                    loginState.selectedUser &&
                                    <section>
                                        <div className="card-title">
                                            Здравейте, <strong>{loginState.selectedUser.user}</strong>!
                                        </div>
                                        <div className="card-subtitle pb-3 pt-2">Моля, въведете Вашата парола...</div>
                                        <form onSubmit={(e) => { e.preventDefault(); doLogin()}}>
                                            <input className="form-control" type="password" ref={passwordInput}/>
                                        </form>
                                    </section>
                                }

                                {
                                    !loginState.selectedUser &&
                                    <section>
                                        {
                                            loginState.api.status === NET_STATUS.LOADING && <> Зареждам списъка със служители ... </>
                                        }
                                        {
                                            loginState.api.status === NET_STATUS.ERROR && <div className="text-warning">Възникна грешка при зареждане на списъка.</div>
                                        }
                                        {
                                            loginState.api.status === NET_STATUS.SUCCESS &&
                                            <form>
                                                <input className="form-control m-0 mb-3"
                                                       onChange={(e) => {
                                                           setLoginState({...loginState, filter: e.target.value.toLowerCase()})
                                                       }}/>

                                                <div className="list-group">
                                                    {
                                                        loginState.users.filter(
                                                            (u: any) => {
                                                                return !loginState.filter || u.user.toLowerCase().indexOf(loginState.filter) > -1;
                                                            }
                                                        ).map(
                                                            (u: any, idx: number) => {
                                                                return <>
                                                                    <UserItemComponent
                                                                        key={idx}
                                                                        user={u}
                                                                        onSelectUser={() => {
                                                                            setLoginState(prevState => {
                                                                                return {...prevState, selectedUser: u}
                                                                            })
                                                                        }}
                                                                    ></UserItemComponent>
                                                                </>
                                                            }
                                                        )
                                                    }
                                                </div>
                                            </form>
                                        }
                                    </section>
                                }
                                {
                                    loginState.selectedUser && loginState.api.status === NET_STATUS.ERROR && <div className="text-warning">Това не е валидна парола. Моля, опитайте пак.</div>
                                }
                            </div>

                            <div className="card-footer text-end">
                                {
                                    loginState.selectedUser &&
                                    <span className="row">
                                        <span className="col text-start">
                                        <button className="btn btn-outline-primary align-top" onClick={
                                            () => {
                                                setLoginState(
                                                    prevState => {
                                                        return {...prevState, selectedUser: null}
                                                    }
                                                )
                                            }
                                        }><FontAwesomeIcon icon={faArrowLeft}/> Списък
                                        </button>
                                        </span>
                                        {
                                            loginState.api.status === NET_STATUS.LOADING ?
                                                <span>Моля, изчакайте ...</span>
                                                :
                                                <button className="col-auto btn btn-primary mr-1" onClick={doLogin}>Вход</button>
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
