import axios, {AxiosRequestConfig} from "axios";
import {API_CALL, NET_STATUS, Property, PropertyValue, SALE_TYPE, Account} from "./types";
import {defaultAxiosResponse, FACTORY} from "./factories";
import {VALIDATION} from "./validations";
import {dateToStringFormatOrNull, isAuthError, isLoading, isSuccess, serializeObject} from "./common";
import {API_PATHS, PATHS} from "./paths";
import moment, {Moment} from "moment";
import {Division} from "./components/form76/Form76Divisions";
import {Employee} from "./components/form76/Form76Employees";
import {CALENDAR_FILTER} from "./components/calendar/filter/CalendarFilter";
import {BudgetType} from "./pages/budgets/BudgetsTypes";


export const API_CLASS = class {
    // public site = 'http://tuhli.3pconsulting.org/api/';
    // private host = 'http://tuhli.3pconsulting.org/api/api/';

    // public site = 'https://taifun.vivisoft-bg.com/api/';
    // private host = 'https://taifun.vivisoft-bg.com/api/api/';


    // ORIGINAL

    public site = 'https://erp.taifun-bg.com/api/';
    private host = 'https://erp.taifun-bg.com/api/api/';



    // public site = 'https://dev.taifun-bg.com/api/';
    // private host = 'https://dev.taifun-bg.com/api/api/';

    // public site = 'http://localhost:3000/';
    // private host = 'http://localhost:3000/api/';

    private token: string | null = null;
    public user: Account|null = null;

    constructor(token: string = '', userid = -1) {
        if (token.length > 0) {
            this.token = token;
            // localStorage.setItem('t', this.token);
        } else {
            // this.token = localStorage.getItem('t');

            const search = window.location.search;
            const params = new URLSearchParams(search);
            this.token = params.get('t');
        }
    }

    public getToken(): string|null {
        return this.token;
    }

    public userHasModul(modul_id: number): boolean {
        for(let i = 0; i < (this.user?.modules?.length || 0); i++) {
            if(this.user?.modules[i].app_modules_id === modul_id) return true;
        }
        return false;
    }

    public getAPITokenAsQuery (query: string = ""): string {
        return (API.getToken() ? (query.length > 0 ? query + "&t=" : "?t=") + API.getToken() : "")
    }


    private checkForAuthError(apiCall: API_CALL) {
        if (isAuthError(apiCall)) {
            window.location.href = PATHS.login;
        }
    }

    private callOnChangeEvent(apiCall: API_CALL) {
        this.checkForAuthError(apiCall);
        if (apiCall && apiCall.onChange) {
            apiCall.onChange(apiCall);
        }
    }

    private callOnChangeEventWithError(apiCall: API_CALL, err: any) {
        apiCall.status = NET_STATUS.ERROR;
        apiCall.data = defaultAxiosResponse;
        apiCall.error = err;

        this.callOnChangeEvent(apiCall);
    }

    private callOnChangeEventWithSuccess(apiCall: API_CALL, res: any) {
        apiCall.status = NET_STATUS.SUCCESS;
        apiCall.data = res;
        apiCall.error = null;
        this.callOnChangeEvent(apiCall);
    }

    public doGetRequest(onChange: any, url: string, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }
        axios.get(this.host + url, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doDeleteRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }
        axios.delete(this.host + url, {...requestConfig, data: data})
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doPostRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.post(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doPutRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.put(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public getAllUsersNotSigned(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.allUsersNotSigned, {});
    }

    public getDepartments(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.properties + '/departments', {});
    }

    public getAllAccounts(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.accounts, {});
    }

    public getAccount(onChange: any, accountId: number) {
        this.doGetRequest(onChange, API_PATHS.accounts + '/' + accountId, {});
    }

    public saveAccount(onChange: any, data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        console.log(onChange, fd);
        this.doPostRequest(onChange, API_PATHS.accounts, fd);
    }

    public deleteAccount(onChange: any, accountId: number) {
        this.doDeleteRequest(onChange, API_PATHS.accounts + "/" + accountId, {});
    }

    public getAppModules(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.appModules, {});
    }

    public getReportCount(onChange: any, reportId: number, report: string, date: string, shift_id: number) {
        this.doGetRequest(onChange, API_PATHS.validateReport + '?report=' + report + '&report_id=' + reportId + '&date=' + date + '&shift=' + shift_id, {});
    }

    public postUserLogin(onChange: any, email: string, password: string) {
        const me = this;
        const _onChange = onChange;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        if (!VALIDATION.validateLoginData(apiCall, email, password)) {
            this.callOnChangeEvent(apiCall);
            return;
        }

        this.doPostRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login, {email: email, password: password}, {});
    }

    public postUserLoginByKey(onChange: any, key: string) {
        const me = this;
        const _onChange = onChange;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);

        this.doGetRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login + '?key=' + key);
    }

    public getUserData(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.user, {});
    }

    public getUserLogout(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.logout, {});
    }

    // PARAMETERS

    public getParameterValues(onChange: any, parameterId: number) {
        const afterLoaded = function (apiCall: API_CALL) {
            if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                apiCall.data.data.items.descriptor = apiCall.data.data.items.descriptorJSON ? JSON.parse(apiCall.data.data.items.descriptorJSON) : {};
                apiCall.data.data.items.values = (apiCall.data.data.items.valuesJSON ? JSON.parse(apiCall.data.data.items.valuesJSON) : []).map(
                    (item: any, idx: number) => {
                        const newPropVal: PropertyValue = {
                            id: idx,
                            value: item['value'],
                            description: {...item}
                        } as PropertyValue;
                        delete newPropVal.description['value'];
                        return newPropVal;
                    }
                );
            }
            onChange(apiCall);
        }
        this.doGetRequest(afterLoaded, API_PATHS.properties + '/' + parameterId, {});
    }

    public getParameterValuesForDate(onChange: any, parameterId: number, forDate: string) {
        const afterLoaded = function (apiCall: API_CALL) {
            if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                apiCall.data.data.items.descriptor = apiCall.data.data.items.descriptorJSON ? JSON.parse(apiCall.data.data.items.descriptorJSON) : {};
                apiCall.data.data.items.values = (apiCall.data.data.items.valuesJSON ? JSON.parse(apiCall.data.data.items.valuesJSON) : []).map(
                    (item: any, idx: number) => {
                        const newPropVal: PropertyValue = {
                            id: idx,
                            value: item['value'],
                            description: {...item}
                        } as PropertyValue;
                        delete newPropVal.description['value'];
                        return newPropVal;
                    }
                );
            }
            onChange(apiCall);
        }
        this.doGetRequest(afterLoaded, API_PATHS.properties + '/' + parameterId + `?forDate=${forDate}`, {});
    }

    public setParameterValues(onChange: any, data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        this.doPostRequest(onChange, API_PATHS.properties, fd);
    }
    public deleteParameterValues(onChange: any, propertyId: number, valueId: number) {
        this.doDeleteRequest(onChange, API_PATHS.properties + '/' + propertyId, {valueId: valueId});
    }


    // CLIENTS
    public getClient(onChange: any, id: number = 0) {
        this.doGetRequest(onChange,API_PATHS.client + '/' + id);
    }

    public getClients(onChange: any, filter: any, pageIdx: number = 0, pageSize: number = 10) {
        let query_str = "?";
        Object.keys(filter).forEach(
            k => query_str += k + "=" + encodeURI(filter[k]) + "&"
        );
        query_str += "page_idx="+pageIdx+"&page_size="+pageSize;
        this.doGetRequest(onChange,API_PATHS.clients + query_str);
    }

    public saveClientData(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.clients, {...data});
    }

    public deleteClientData(onChange: any, clientId: number) {
        this.doDeleteRequest(onChange, API_PATHS.clients + '/' + clientId, {});
    }

    public getClientAccounts(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/accounts/' + clientId);
    }

    public getClientPaletList(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/palets/' + clientId);
    }

    public getClientPaletListIso(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/palets-iso/' + clientId);
    }

    public getClientLogisticList(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/logistic/' + clientId);
    }

    public setClientPaletStartBalance(onChange: any, clientId: number, balance: number) {
        this.doPostRequest(onChange,API_PATHS.clients + '/palets/balance/' + clientId, {balance: balance});
    }

    public setClientPaletStartBalanceIso(onChange: any, clientId: number, balance: number) {
        this.doPostRequest(onChange,API_PATHS.clients + '/palets-iso/balance/' + clientId, {balance: balance});
    }

    public getClientPriceList(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/pricelist/' + clientId);
    }

    public setClientPriceList(onChange: any, clientId: number, priceList: any) {
        this.doPostRequest(onChange,API_PATHS.clients + '/pricelist/' + clientId, priceList);
    }

    // CLIENT OPERATIONS

    public getClientOperations(onChange: any, clientId: number) {
        this.doGetRequest(onChange,API_PATHS.clients + '/accounts/' + clientId);
    }

    public saveClientOperation(onChange: any, data: any, clientId: number, operationId: number) {
        this.doPostRequest(onChange,API_PATHS.clients + '/accounts/' + operationId, {...data, client_id: clientId});
    }

    public deleteClientOperation(onChange: any, operationId: number) {
        this.doDeleteRequest(onChange,API_PATHS.clients + '/accounts/' + operationId, {});
    }



    // LOGISTICS
    public getLogistics(onChange: any, filter: any, pageIdx: number = 0, pageSize: number = 10) {
        let query_str = "?";
        Object.keys(filter).forEach(
            k => query_str += k + "=" + encodeURI(filter[k]) + "&"
        );
        query_str += "page_idx="+pageIdx+"&page_size="+pageSize;
        this.doGetRequest(onChange,API_PATHS.logistics + query_str);
    }

    public saveLogistic(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.logistics, {...data});
    }

    public deleteLogistic(onChange: any, clientId: number) {
        this.doDeleteRequest(onChange, API_PATHS.logistics + '/' + clientId, {});
    }

    // EXPORT NOTES
    public getExpeditionNote(onChange: any, filter: any, saleType: SALE_TYPE|null, pageIdx: number = 0, pageSize: number = 10, only_not_paid: boolean = false) {
        let query_str = "?";
        Object.keys(filter).forEach(
            k => query_str += k + "=" + encodeURI(filter[k]) + "&"
        );
        if(saleType) query_str += "sale_type="+Object.keys(SALE_TYPE)[Object.values(SALE_TYPE).indexOf(saleType as SALE_TYPE)]+'&';
        query_str += "page_idx="+pageIdx+"&page_size="+pageSize;
        query_str += "&only_not_paid="+only_not_paid;
        this.doGetRequest(onChange,API_PATHS.expeditions_notes + query_str);
    }

    public saveExpeditionNote(onChange: any, data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        if(!fd.has("pay_type")) {
            fd.set("pay_type", "");
        }
        this.doPostRequest(onChange, API_PATHS.expeditions_notes, fd);
    }

    public generateExpeditionNoteFromPlan(onChange: any, planId: number) {
        const fd = new FormData();
        fd.append('planId', planId.toString());
        this.doPostRequest(onChange, API_PATHS.expeditions_notes + '/generate', fd);
    }

    public deleteExpeditionNote(onChange: any, expNoteId: number) {
        this.doDeleteRequest(onChange, API_PATHS.expeditions_notes + '/' + expNoteId, {});
    }

    public printExpeditionNote(onChange: any, expNoteId: number) {
        this.doGetRequest(onChange, API_PATHS.expeditions_notes + '/print/' + expNoteId, {});
    }

    public getExpeditionNoteDetails(onChange: any, expNoteId: number) {
        this.doGetRequest(onChange, API_PATHS.expeditions_notes + '/' + expNoteId, {});
    }

    public getClientNotPaidSales(onChange: any, clientId: number) {
        this.doGetRequest(onChange, API_PATHS.expeditions_notes + '/not-paid/' + clientId, {});
    }

    public recalculateSalesForClientId(onChange: any, clientId: number) {
        this.doGetRequest(onChange, API_PATHS.expeditions_notes + '/recalculateSalesForClientId/' + clientId, {});
    }

    // GRINDING

    public saveGrinding(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.grinding, {...data, reportId: reportId});
    }

    public deleteGrinding(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.grinding + "/" + reportId, {});
    }

    public getGrinding(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.grinding + "/" + forDate.format('YYYY-MM-DD'));
    }

    // REFUELING

    public saveRefueling(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.refueling, {...data, reportId: reportId});
    }

    public deleteRefueling(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.refueling + "/" + reportId, {});
    }

    public getRefueling(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.refueling + "/" + forDate.format('YYYY-MM-DD'));
    }


    // EXTRUDER

    public saveExtruder(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.extruder, {...data, reportId: reportId});
    }

    public deleteExtruder(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.extruder + "/" + reportId, {});
    }

    public getExtruder(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.extruder + "/" + forDate.format('YYYY-MM-DD'));
    }

    // HEATER

    public saveHeater(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.heater, {...data, reportId: reportId});
    }

    public deleteHeater(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.heater + "/" + reportId, {});
    }

    public getHeater(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.heater + "/" + forDate.format('YYYY-MM-DD'));
    }


    // ORDERING

    public saveOrdering(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.ordering, {...data, reportId: reportId});
    }

    public deleteOrdering(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.ordering + "/" + reportId, {});
    }

    public getOrdering(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.ordering + "/" + forDate.format('YYYY-MM-DD'));
    }


    // OVEN

    public saveOven(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.oven, {...data, reportId: reportId});
    }

    public deleteOven(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.oven + "/" + reportId, {});
    }

    public getOven(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.oven + "/" + forDate.format('YYYY-MM-DD'));
    }


    // UNLOADING

    public saveUnloading(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.unloading, {...data, reportId: reportId});
    }

    public deleteUnloading(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.unloading + "/" + reportId, {});
    }

    public getUnloading(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.unloading + "/" + forDate.format('YYYY-MM-DD'));
    }


    // FuEL BALANCE
    public getFuelBalance(onChange: any, fromYear: number, fromMonth: number, monthCount: number) {
        this.doGetRequest(onChange, API_PATHS.fuel_balance + "?start_year=" + fromYear + "&start_month=" + fromMonth + "&month_count=" + monthCount);
    }


    // BANK EXPENSES
    public getBankExpenses(onChange: any, fromYear: number, fromMonth: number, monthCount: number, fromDate?: Date, toDate?: Date) {
        this.doGetRequest(onChange, API_PATHS.bank_expenses + "?start_year=" + fromYear + "&start_month=" + fromMonth + "&month_count=" + monthCount +
            "&from_date=" + (fromDate ? moment(fromDate).format("YYYY-MM-DD") : "") +
            "&to_date=" + (toDate ? moment(toDate).format("YYYY-MM-DD") : ""));
    }

    public setBankExpenses(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.bank_expenses, data);
    }


    // PLANNING
    public getPlanningYears(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.planning + "/years" );
    }

    public getPlanning(onChange: any, forYear: string, withCompleted: boolean) {
        this.doGetRequest(onChange, API_PATHS.planning + "/" + forYear + '?completed=' + withCompleted );
    }

    public getPlanningDetails(onChange: any, id: number) {
        this.doGetRequest(onChange, API_PATHS.planning + "/plan/" + id );
    }

    public deletePlanning(onChange: any, id: number) {
        this.doDeleteRequest(onChange, API_PATHS.planning + "/" + id, {} );
    }

    public checkForPlanning(onChange: any, id: number, date: string) {
        this.doGetRequest(onChange, API_PATHS.planning + "/plan/" + id + "/" + date );
    }

    public savePlanning(onChange: any, data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        this.doPostRequest(onChange, API_PATHS.planning, fd);
    }



    // FORM 76
    public getForm76Day(onChange: any, forDay: string) {
        this.doGetRequest(onChange, API_PATHS.form76 + "/day/" + forDay );
    }

    public getForm76Month(onChange: any, fromDate: string, toDate: string) {
        this.doGetRequest(onChange, API_PATHS.form76 + "/month/" + fromDate + "/" + toDate );
    }

    public updateForm76(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.form76, data);
    }

    public updateForm76Rates(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.form76 + '/rates', data);
    }



    public getForm76Divisions(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.form76 + "/divisions");
    }

    public addForm76Division(onChange: any, name: string) {
        this.doPostRequest(onChange, API_PATHS.form76 + "/divisions", {name: name});
    }

    public updateForm76Division(onChange: any, d: Division) {
        this.doPutRequest(onChange, API_PATHS.form76 + "/divisions", d);
    }

    public deleteForm76Division(onChange: any, d: Division) {
        this.doDeleteRequest(onChange, API_PATHS.form76 + "/divisions", d);
    }


    public getForm76Employees(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.form76 + "/employees");
    }

    public addForm76Employee(onChange: any, d: Employee) {
        this.doPostRequest(onChange, API_PATHS.form76 + "/employees", d);
    }

    public updateForm76Employee(onChange: any, d: Employee) {
        this.doPutRequest(onChange, API_PATHS.form76 + "/employees", d);
    }

    public deleteForm76Employee(onChange: any, d: Employee) {
        this.doDeleteRequest(onChange, API_PATHS.form76 + "/employees", d);
    }




    // DAILY_REPORT
    public getDailyReports(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.daily_report + "/" + forDate.format('YYYY-MM-DD'));
    }

    public getFullDailyReports(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.full_daily_report + "/" + forDate.format('YYYY-MM-DD'));
    }

    public saveFullDailyReports(onChange: any, data: any, forDate: Moment) {
        this.doPostRequest(onChange, API_PATHS.full_daily_report + "/" + forDate.format('YYYY-MM-DD'), data);
    }



    // REPAIRS

    public saveRepairs(onChange: any, data: any, reportId: number = -1) {
        this.doPostRequest(onChange, API_PATHS.repairs, {...data, reportId: reportId});
    }

    public deleteRepairs(onChange: any, reportId: number = -1) {
        this.doDeleteRequest(onChange, API_PATHS.repairs + "/" + reportId, {});
    }

    public getRepairs(onChange: any, forDate: Moment) {
        this.doGetRequest(onChange, API_PATHS.repairs + "/" + forDate.format('YYYY-MM-DD'));
    }


    // STANDARTS

    public postStandarts(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.standarts, data);
    }

    public getStandarts(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.standarts);
    }


    // REPORTS

    public calcProductionReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.production_report, data);
    }

    public calcRepairsReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.repairs_report, data);
    }

    public calcBricksProducedReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.bricks_produced, data);
    }

    public calcBricksWasteReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.bricks_waste, data);
    }
    public calcBricksWasteByTypeReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.bricks_waste_by_type, data);
    }

    public calcFuelReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.fuel_report, data);
    }

    public calcMaterialsReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.materials_report, data);
    }

    public calcCommentsReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.comments_report, data);
    }
    public calcBricksSalesReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.bricks_sales_report, data);
    }
    public calcPeletsSalesReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.pelets_sales_report, data);
    }
    public calcSludgePeletsSaldoReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.sludge_pelets_saldo_report, data);
    }
    public calcIncomeOutgoingReport(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.income_outgoing_report, data);
    }
    public getAccountBalance(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.account_balance, data);
    }
    public getAccountWithNotPaid(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.account_not_paid, data);
    }
    public getClientRatingByOborot(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.client_rating_by_oborot, data);
    }
    public getAccountPaletsBalance(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.account_palets_balance, data);
    }
    public getProfitReport(onChange: any, fromYear: number, fromMonth: number, monthCount: number) {
        this.doGetRequest(onChange, API_PATHS.profit_report + "?start_year=" + fromYear + "&start_month=" + fromMonth + "&month_count=" + monthCount);
    }



    // CALENDAR

    /// EXECUTORS

    public getCalendarExecutorsList(onChange: any, mode: any, withTrashed: boolean = false) {
        this.doGetRequest(onChange, API_PATHS.calendar + '/executors/' + mode + (withTrashed ? '?trashed=true' : ''));
    }

    public saveCalendarExecutor(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.calendar + '/executors', data);
    }

    public deleteCalendarExecutor(onChange: any, id: number) {
        this.doDeleteRequest(onChange, API_PATHS.calendar + '/executors/' + id, null);
    }

    /// ELEMENTS

    public getCalendarElementsList(onChange: any, mode: any, withTrashed: boolean = false) {
        this.doGetRequest(onChange, API_PATHS.calendar + '/elements/' + mode + (withTrashed ? '?trashed=true' : ''));
    }

    public saveCalendarElement(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.calendar + '/elements', data);
    }

    public deleteCalendarElement(onChange: any, id: number) {
        this.doDeleteRequest(onChange, API_PATHS.calendar + '/elements/' + id, null);
    }


    /// ALERTS

    public getCalendarAlerts(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.calendar + '/alerts');
    }

    /// MESSAGES

    public getNewMessagesCount(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.messages + '/new');
    }

    public getSendedMessages(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.messages + '/sended');
    }

    public getReceivedMessages(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.messages + '/received');
    }

    public sendMessage(onChange: any, title: string, message: string, receiverId: number) {
        this.doPostRequest(onChange, API_PATHS.messages, {title: title, message: message, receiver_id: receiverId});
    }

    public removeMessage(onChange: any, id: number) {
        this.doPostRequest(onChange, API_PATHS.messages + '/remove/' + id.toString(), {});
    }
    public readMessage(onChange: any, id: number) {
        this.doPostRequest(onChange, API_PATHS.messages + '/read/' + id.toString(), {});
    }



    /// INTERNALS

    public getCalendarInternalsList(onChange: any, mode: any, withTrashed: boolean = false) {
        this.doGetRequest(onChange, API_PATHS.calendar + '/internals/' + mode + (withTrashed ? '?trashed=true' : ''));
    }


    /// EVENTS

    public getCalendarEventsList(onChange: any, mode: any, history: boolean, filter: any) {
        let qry = '?'+(history ? 'history=yes&' : '') + (filter ? serializeObject(filter, true) : '');
        this.doGetRequest(onChange, API_PATHS.calendar + '/events/' + mode + qry);
    }
    public saveCalendarEvent(onChange: any, data: any) {
        this.doPostRequest(onChange, API_PATHS.calendar + '/events', data);
    }

    public deleteCalendarEvent(onChange: any, id: number) {
        this.doDeleteRequest(onChange, API_PATHS.calendar + '/events/' + id, null);
    }


    /// MEDIA

    public getCurrentStatus(onChange: any) {
        this.doGetRequest(onChange, API_PATHS.current_status);
    }

    /// IO REF

    public getIOReference(onChange: any, year: number) {
        if(!year) year = moment().year();
        this.doGetRequest(onChange, API_PATHS.io_reference + '/' + year);
    }

    public setIOInvestement(onChange: any, year: number, month: number, amount: number) {
        this.doPostRequest(onChange, API_PATHS.io_reference, {
            year, month, amount
        });
    }


    /// BUDGETS

    public getBudgets(onChange: any, year: number) {
        if(!year) year = moment().year();
        this.doGetRequest(onChange, API_PATHS.budgets + '/' + year);
    }

    public setBudgetValue(onChange: any, expenceType: BudgetType, expenceId: string, year: number, month: number, value: number) {
        this.doPostRequest(onChange, API_PATHS.budgets + '/' + expenceType + '/' + expenceId + '/' + year + '/' + month, {
            value: value
        });
    }


}

export const API = new API_CLASS();
