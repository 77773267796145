import {BonusPlusConfigType} from "./BonusPlusConfigComponent";
import {Form, Table} from "react-bootstrap";
import React, {useEffect} from "react";

interface BonusPlusConfigParameterTableProps {
    isLessIsMore: boolean;
    userMaxBonus: number;
    daysCnt: number;
    config: BonusPlusConfigType,
    onChange?: (c: BonusPlusConfigType) => void
}

export default function BonusPlusConfigParameterTable({
                                                          isLessIsMore,
                                                          userMaxBonus,
                                                            daysCnt,
                                                          config,
                                                          onChange
                                                      }: BonusPlusConfigParameterTableProps) {
    const [newConfig, setNewConfig] = React.useState<BonusPlusConfigType>(config);

    const [downStepValue, setDownStepValue] = React.useState<string>(config.downStep.toString());
    const [downStepBonusValue, setDownStepBonusValue] = React.useState<string>(config.downStepBonus.toString());
    const [standartValue, setStandartValue] = React.useState<string>(config.standart.toString());
    const [upStepValue, setUpStepValue] = React.useState<string>(config.upStep.toString());
    const [upStepBonusValue, setUpStepBonusValue] = React.useState<string>(config.upStepBonus.toString());

    useEffect(() => {
        setDownStepValue(config.downStep.toString());
        setDownStepBonusValue(config.downStepBonus.toString());
        setStandartValue(config.standart.toString());
        setUpStepValue(config.upStep.toString());
        setUpStepBonusValue(config.upStepBonus.toString());
    }, [config]);

    useEffect(() => {
        newConfig.standartBonus = userMaxBonus / 100 * newConfig.standartWeight;
        if (onChange) onChange(newConfig);
    }, [newConfig]);

    const worseResult = isLessIsMore ? "Повече" : "По-малко";
    const betterResult  = isLessIsMore ? "По-малко" : "Повече";

    return (
        <Table size={"sm"} bordered>
            <thead>
            <tr>
                <th rowSpan={2}></th>
                <th rowSpan={2} className={"text-end"}>Стойност</th>
                <th colSpan={2} className={"text-center"}>Бонус</th>
            </tr>
            <tr>
                <th className={"text-end"}>мес</th>
                <th className={"text-end"}>ден</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={"text-nowrap w-80px"}>{betterResult}</td>
                <td>
                    <Form.Control value={downStepValue} size={"sm"} className={"text-end"}
                                  onChange={(e) => {
                                      if (!isNaN(parseFloat(e.target.value))) setDownStepValue(e.target.value);
                                  }}
                                  onBlur={() => {
                                      const newConfig = {...config, downStep: parseFloat(downStepValue)};
                                      setNewConfig(newConfig);
                                  }}
                    />
                </td>
                <td>
                    <Form.Control value={downStepBonusValue} size={"sm"} className={"text-end"}
                                  onChange={(e) => {
                                      if (!isNaN(parseFloat(e.target.value))) setDownStepBonusValue(e.target.value);
                                  }}
                                  onBlur={() => {
                                      const newConfig = {...config, downStepBonus: parseFloat(downStepBonusValue)};
                                      setNewConfig(newConfig);
                                  }}
                    />
                </td>
                <td>
                    <Form.Control value={Math.round(config.downStepBonus / daysCnt)} readOnly={true} disabled={true} size={"sm"} className={"text-end"}/>
                </td>
            </tr>
            <tr className={"bg-success-light"}>
                <td>
                    100%
                </td>
                <td>
                    <Form.Control value={standartValue} size={"sm"} className={"text-end"}
                                  onChange={(e) => {
                                      if (!isNaN(parseFloat(e.target.value))) setStandartValue(e.target.value);
                                  }}
                                  onBlur={() => {
                                      const newConfig = {...config, standart: parseFloat(standartValue)};
                                      setNewConfig(newConfig);
                                  }}
                    />
                </td>
                <td>
                    <Form.Control value={userMaxBonus * config.standartWeight / 100} size={"sm"}
                                  className={"bg-light text-end"} readOnly
                    />
                </td>
                <td>
                    <Form.Control value={Math.round(config.standartBonus / daysCnt)} readOnly={true} disabled={true} size={"sm"} className={"text-end"}/>
                </td>
            </tr>
            <tr>
                <td className={"text-nowrap"}>{worseResult}</td>
                <td>
                    <Form.Control value={upStepValue} size={"sm"} className={"text-end"}
                                  onChange={(e) => {
                                      if (!isNaN(parseFloat(e.target.value))) setUpStepValue(e.target.value);
                                  }}
                                  onBlur={() => {
                                      const newConfig = {...config, upStep: parseFloat(upStepValue)};
                                      setNewConfig(newConfig);
                                  }}
                    />
                </td>
                <td>
                    <Form.Control value={upStepBonusValue} size={"sm"} className={"text-end"}
                                  onChange={(e) => {
                                      if (!isNaN(parseFloat(e.target.value))) setUpStepBonusValue(e.target.value);
                                  }}
                                  onBlur={() => {
                                      const newConfig = {...config, upStepBonus: parseFloat(upStepBonusValue)};
                                      setNewConfig(newConfig);
                                  }}
                    />
                </td>
                <td>
                    <Form.Control value={Math.round(config.upStepBonus / daysCnt)} readOnly={true} disabled={true} size={"sm"} className={"text-end"}/>
                </td>
            </tr>
            </tbody>
        </Table>
    )
}