import React from "react";
import {AccountOperation, API_CALL, Client, OPERATION_TYPE, PAY_TYPE} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";
import {forEach} from "react-bootstrap/ElementChildren";

export interface EditClientPaletDetailsProps {
    rerenderKey?: number,
    client: Client,
    onCancel: () => any,
    onRefresh?: () => any,
    onEditSale?: (saleId: number) => any,
    isIso: boolean
}

export interface EditClientPaletDetailsState {
    loading: boolean;
    report: any[];
    acReport: any[];
    balance: number;
    startBalance: number;
    saving: boolean;
    isIso: boolean;
}

export class EditClientPaletDetails extends React.Component<EditClientPaletDetailsProps, EditClientPaletDetailsState> {
    constructor(props: EditClientPaletDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            report: [],
            acReport: [],
            balance: 0,
            startBalance: 0,
            isIso: props.isIso
        }
        this.cancelClient = this.cancelClient.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<EditClientPaletDetailsProps>, prevState: Readonly<EditClientPaletDetailsState>, snapshot?: any) {
        if (prevProps.rerenderKey !== this.props.rerenderKey) {
            this.loadData();
        }
    }

    loadData() {
        if(this.state.isIso) {
        API.getClientPaletListIso(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    this.setState({
                        report: apiCall.data.data.report_iso,
                        acReport: apiCall.data.data.acReport_iso,
                        balance: apiCall.data.data.balance_iso,
                        startBalance: apiCall.data.data.start_balance_iso,
                    });
                }
            }, this.props.client.id
        );
        } else {
            API.getClientPaletList(
                (apiCall: API_CALL) => {
                    this.setState({loading: isLoading(apiCall)});
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка');
                    }
                    if (isSuccess(apiCall)) {
                        this.setState({
                            report: apiCall.data.data.report,
                            acReport: apiCall.data.data.acReport,
                            balance: apiCall.data.data.balance,
                            startBalance: apiCall.data.data.start_balance,
                        });
                    }
                }, this.props.client.id
            );
        }
    }

    cancelClient() {
        this.props.onCancel();
    }

    renderReport() {
        const html: any[] = [];
        const dates = Object.keys(this.state.report);
        const datesContent = Object.values(this.state.report);

        dates.forEach(
            (date: string, idx: number) => {
                const notesNumbers = Object.keys(datesContent[idx]);

                notesNumbers.forEach(
                    (number: string, idx1: number) => {
                        const noteVals: any = Object.values(datesContent[idx]);
                        if (idx1 === 0) {
                            html.push(
                                <tr key={"palet_filter_" + idx + "_" + idx1}>
                                    <td rowSpan={notesNumbers.length}>{date}</td>
                                    <td>{number}</td>
                                    <td>{noteVals[idx1].type}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].getted}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].returned}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].payed}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        color: (noteVals[idx1].balance < 0 ? 'red' : 'green')
                                    }}>{noteVals[idx1].balance}</td>
                                </tr>);
                        } else {
                            html.push(
                                <tr key={"palet1_" + number + '_' + idx1}>
                                    <td>{number}</td>
                                    <td>{noteVals[idx1].type}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].getted}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].returned}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].payed}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        color: (noteVals[idx1].balance < 0 ? 'red' : 'green')
                                    }}>{noteVals[idx1].balance}</td>
                                </tr>);
                        }
                    }
                )
            }
        )

        return html;
    }

    saveStartBalance() {
        if(this.state.isIso) {
            API.setClientPaletStartBalanceIso(
                (apiCall: API_CALL) => {
                    this.setState({saving: isLoading(apiCall)});
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка', translateError);
                    }
                    if (isSuccess(apiCall)) {
                        this.loadData();
                        if (this.props.onRefresh) this.props.onRefresh();
                    }
                }, this.props.client.id, this.state.startBalance
            );
        } else {
            API.setClientPaletStartBalance(
                (apiCall: API_CALL) => {
                    this.setState({saving: isLoading(apiCall)});
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Грешка', translateError);
                    }
                    if (isSuccess(apiCall)) {
                        this.loadData();
                        if (this.props.onRefresh) this.props.onRefresh();
                    }
                }, this.props.client.id, this.state.startBalance
            );
        }
    }

    render() {
        return (
            <div className={"col align-content-between"}>
                <div className="row">
                    <div className="col-12 mr-0 pr-0">
                        <div className={"row w-100"}>
                            <div className="col-6"><label>ТЕКУЩ БАЛАНС:</label></div>
                            <div
                                className={"col-6 text-end font-weight-bold text-light pt-1 " + (this.state.balance < 0 ? "bg-danger" : "bg-success")}>
                                {this.state.balance} бр.
                            </div>
                        </div>

                        <div className={"row d-block w-100 col-12 m-0 p-0"}
                             style={{maxHeight: "50vh", overflow: "auto"}}>
                            <table className={"table table-sm m-0 p-0"}>
                                <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Номер</th>
                                    <th>Тип</th>
                                    <th className={"text-end"}>Взети</th>
                                    <th className={"text-end"}>Върнати</th>
                                    <th className={"text-end"}>Платени</th>
                                    <th className={"text-end"}>Баланс</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.report.length == 0 &&
                                    <tr>
                                        <td colSpan={6}>
                                            {this.state.loading ? 'Зареждам ...' : 'Няма данни'}
                                        </td>
                                    </tr>
                                }
                                {
                                    this.renderReport()
                                }
                                {
                                    this.state.acReport.length > 0 &&
                                    <>
                                        <tr>
                                            <td colSpan={7}
                                                className={"text-center font-weight-bold bg-secondary-light"}>БРОЙ
                                                ПРИЗНАТИ И БРАКУВАНИ ПАЛЕТИ (от АВАНС КРЕДИТНО)
                                            </td>
                                        </tr>
                                        {
                                            this.state.acReport.map(
                                                (item: any, idx: number) => {
                                                    return (
                                                        <tr key={"ac_report_" + idx}>
                                                            <td>{item.date}</td>
                                                            <td colSpan={5}>{item.description}</td>
                                                            <td className={"text-end"}>{item.approvedPaletes + item.scrappedPaletes}</td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                    </>
                                }
                                </tbody>
                            </table>
                        </div>

                        <div className={"row p-2 m-0 mt-1 bg-light border justify-content-center"}>
                            <div className="col-6 justify-content-center pt-1"><label>НАЧАЛЕН БАЛАНС:</label></div>
                            <div className={"col-6 text-end font-weight-bold text-light pt-1 "}>
                                <form className={"form align-content-end flex-fill"}>
                                    <button type={"button"} className={"btn btn-sm btn-primary float-right ml-1 mr-0"}
                                            onClick={() => this.saveStartBalance()}>Запази
                                    </button>
                                    <input className="form-control form-control-sm w-120px text-end float-right"
                                           type="number"
                                           value={this.state.startBalance}
                                           onChange={(e) => {
                                               const newVal = Number.parseInt(e.target.value);
                                               if (!isNaN(newVal)) this.setState({startBalance: newVal});
                                           }}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
