import {
    Button,
    CloseButton,
    Col,
    FormSelect,
    Modal,
    Row,
    Tab,
    Tabs
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {API_CALL, MONTHS_NAMES} from "../../types";
import {faCoins} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
    isError,
    isLoading,
    isSuccess, mapToObject,
    objectToMap,
    showErrorsInToast, showMsgInToast,
    stringToCurrency,
    translateError
} from "../../common";
import {BonusPlusConfigComponent, BonusPlusConfigType, BonusPlusConfigTypeType} from "./BonusPlusConfigComponent";
import {BonusPlusCurrentComponent} from "./BonusPlusCurrentComponent";

export interface BonusUser {
    id: number,
    name: string,
    position: string,
    config: Map<BonusPlusConfigTypeType, BonusPlusConfigType>,
    maxBonus: number,
}

export const BONUS_ADMIN_USERS = [50, 53, 55];

export const API_PATH_CONFIG = 'admin/bonus-plus/config';

export default function BonusPlusControlModal({visible, onHide}: { visible: boolean; onHide: () => any; }) {
    const [activeKey, setActiveKey] = useState("overview");
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState((moment().month()).toString());
    const [year, setYear] = useState(moment().year().toString());
    const [yearMonth, setYearMonth] = useState(month + year);
    const [copyData, setCopyData] = useState<Array<BonusUser>>([]);

    const [yearsList, setYearsList] = useState<Array<string>>([]);
    const [users, setUsers] = useState<Array<BonusUser>>([]);
    const [newData, setNewData] = useState<Array<BonusUser>>([]);

    useEffect(
        () => {
            const newYearsList: Array<string> = [];
            for (let i = 0; i < 3; i++) newYearsList.push((moment().year() - i).toString());
            setYearsList(newYearsList);
        }, []
    );

    const loadConfig = () => {
        setLoading(true);
        API.doGetRequest(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    if (apiCall.data.data.users) {
                        const uList = apiCall.data.data.users.map(
                            (u: any) => {
                                return {
                                    id: u.user.id,
                                    name: u.user.name,
                                    position: u.user.position,
                                    minBonus: stringToCurrency(u.min_bonus) || 0,
                                    maxBonus: stringToCurrency(u.max_bonus) || 0,
                                    config: objectToMap(JSON.parse(u.bonus_config))
                                } as BonusUser
                            }
                        )
                        setUsers(uList);
                    } else setUsers([]);
                }
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError)
            }, API_PATH_CONFIG + '/' + (parseInt(month) + 1).toString() + '/' + year, {}
        );
    }

    function loadData() {
        loadConfig();
    }

    useEffect(() => {
        if (year && month) loadData()
    }, [year, month]);


    function copyConfig() {
        setCopyData([...users]);
    }

    function postConfig() {
        if (copyData && copyData.length > 0) {
            setUsers([...copyData]);
        }
    }

    function saveConfig() {
        if (newData && !saving) {
            const data = newData.map(i => {
                return {...i, config: mapToObject(i.config)}
            });

            API.doPostRequest(
                (apiCall: API_CALL) => {
                    setSaving(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        showMsgInToast('Параметрите са запазени усешно!');
                        loadData();
                    }
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Възникна грешка:', translateError)
                    }
                }, API_PATH_CONFIG + '/' + (parseInt(month) + 1).toString() + '/' + year, data
            )
        }
    }

    return (
        <>
            <Modal animation={true} show={visible} dialogClassName={"modal-90"}>
                <Modal.Header>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faCoins}/> Бонус
                    </Modal.Title>
                    <Row>
                        <Col style={{fontSize: 14, textAlign: "right"}}>
                            <Tabs variant="pills" activeKey={month}
                                  onSelect={(k) => setMonth(k || '0')}
                            >
                                {
                                    MONTHS_NAMES.map(
                                        (m, i) => <Tab title={m} eventKey={i} key={i}/>
                                    )
                                }
                            </Tabs>
                        </Col>
                        <Col xs="auto">
                            <FormSelect className="form-control w-100px" value={year}
                                        onChange={(e) => setYear(e.currentTarget.value)}
                            >
                                {yearsList.map(y =>
                                    <option value={y} key={y}>{y}</option>)}
                            </FormSelect>
                        </Col>
                        <Col xs="auto">
                            <CloseButton onClick={() => onHide()} style={{height: 30}}/>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        className={"taifunTheme"}
                        activeKey={activeKey}
                        onSelect={k => setActiveKey(k || 'overview')}
                    >
                        <Tab eventKey={"overview"} title={"Текущо състояние"}  >
                            {
                                activeKey === "overview" && yearMonth !== '' && !loading && <>
                                    <BonusPlusCurrentComponent year={year} month={month}/>
                                </>
                            }
                        </Tab>
                        {
                            BONUS_ADMIN_USERS.indexOf(API.user?.id || -1) > -1 &&
                            <Tab eventKey={"config"} title={"Параметри"}>
                                <BonusPlusConfigComponent users={users}
                                                          month={parseInt(month) ?? new Date().getMonth()}
                                                          year={parseInt(year) ?? new Date().getFullYear()}
                                                          onChangeData={newData => setNewData([...newData])}/>
                            </Tab>
                        }
                    </Tabs>

                    {
                        loading && <div className={"m-3"}>Зареждам данните ...</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Row className={"w-100"}>
                            {
                                activeKey === "config" &&
                                <Col>
                                    <Button variant={"outline-secondary"} onClick={copyConfig}>
                                        Копирай
                                    </Button>&nbsp;
                                    {
                                        copyData && copyData.length > 0 &&
                                        <Button variant={"outline-secondary"} onClick={postConfig}>
                                            Постави
                                        </Button>
                                    }
                                </Col>
                            }
                            <Col className={"text-end"}>
                                {
                                    activeKey === "config" &&
                                    <Button variant={"primary"} onClick={saveConfig}>
                                        {
                                            !saving ? 'Запази параметрите' : 'Запазвам промените...'
                                        }
                                    </Button>
                                }
                                &nbsp;<Button variant={"secondary"} onClick={onHide}>
                                Затвори
                            </Button>
                            </Col>
                        </Row>
                    }

                </Modal.Footer>
            </Modal>
        </>
    )
}
